import { extend } from "vee-validate";

import { i18n } from './../main.js'

extend("minmax", {
  validate(value, args) {
    const length = value.length;
    return {
      required: true,
      valid: length >= args.min && length <= args.max && /^[A-Z0-9]*$/.test(value)
    };
    return i18n.t("PROMOS.VALIDATION_MSGS.LABEL");
  },
  params: ["min", "max"],
  computesRequired: true,
  message: i18n.t("PROMOS.VALIDATION_MSGS.LABEL")
});

extend("minmaxPercentage", {
  validate(value, args) {
    if (args.visible === "true") {
      if (value != "") {
        let val = parseInt(value);
        return {
          required: true,
          valid:
            val >= parseInt(args.min) && val < parseInt(args.max) && value != ""
        };
      } else {
        return {
          required: true,
          valid: false
        };
      }
    }
    return {
      required: false,
      valid: true
    };
    return i18n.t("PROMOS.VALIDATION_MSGS.PERCENTAGE");
  },
  params: ["min", "max", "visible"],
  message: i18n.t("PROMOS.VALIDATION_MSGS.PERCENTAGE"),
  computesRequired: true
});

extend("requiredAmt", {
  validate(value, args) {
    if (args.visible === "true") {
        const len = value.length
        return {
          required: true,
          valid: len > 0 && /^\d*\.?\d*$/.test(value) && parseInt(value) != 0
        };
    }
    return {
      required: false,
      valid: true
    };
    return i18n.t("PROMOS.VALIDATION_MSGS.AMOUNT");
  },
  params: ["visible"],
  computesRequired: true
});

extend("maxDiscount", {
  validate(value, args) {
    if (parseFloat(value) < parseFloat(args.max)) {
      return true;
    }
    return i18n.t("PROMOS.VALIDATION_MSGS.MAX_DISCOUNT");
  },
  params: ["max"],
  message: i18n.t("PROMOS.VALIDATION_MSGS.MAX_DISCOUNT")
});

extend("onlyNumbers", {
  validate(value) {
    return {
      required: false,
      valid: /^\d*\.?\d*$/.test(value) && parseInt(value) != 0
    };
    return i18n.t("PROMOS.VALIDATION_MSGS.REQ");
  },
  message: i18n.t("PROMOS.VALIDATION_MSGS.REQ")
});

extend("requiredOnlyNumbers", {
  validate(value) {
    const len = value.length;
    return {
      required: true,
      valid: len > 0 && /^\d*\.?\d*$/.test(value) && parseInt(value) != 0
    };
    return i18n.t("PROMOS.VALIDATION_MSGS.REQ");
  },
  computesRequired: true
});

extend("required", {
  validate(value) {
    const len = value.length;
    return {
      required: true,
      valid: len > 0 || value > 0
    };
    return i18n.t("PROMOS.VALIDATION_MSGS.REQ");
  },
  computesRequired: true
});

extend("requiredPromoType", {
  validate(value) {
    return {
      required: true,
      valid: value != "" && value != null
    };
    return i18n.t("PROMOS.VALIDATION_MSGS.REQ");
  },
  computesRequired: true
});

extend("requiredPayMethods", {
  validate(value, args) {
    if (args.visible === "true") {
      return {
        required: true,
        valid: value != ""
      };
    } else {
      return {
        required: false,
        valid: true
      };
      return i18n.t("PROMOS.VALIDATION_MSGS.REQ");
    }
    return i18n.t("PROMOS.VALIDATION_MSGS.REQ");
  },
  params: ["visible"],
  computesRequired: true
});

extend("requiredBank", {
  validate(value, args) {
    if (args.visible === "true") {
      return {
        required: true,
        valid: value != ""
      };
    } else {
      return {
        required: false,
        valid: true
      };
      return i18n.t("PROMOS.VALIDATION_MSGS.MIN_PUR_AMT");
    }
    return i18n.t("PROMOS.VALIDATION_MSGS.MIN_PUR_AMT");
  },
  params: ["visible"],
  computesRequired: true
});

extend("requiredPromoChan", {
  validate(value, args) {
    if (args.visible === "true") {
      return {
        required: true,
        valid: value != ""
      };
    } else {
      return {
        required: false,
        valid: true
      };
      return i18n.t("PROMOS.VALIDATION_MSGS.REQ");
    }
    return i18n.t("PROMOS.VALIDATION_MSGS.REQ");
  },
  params: ["visible"],
  computesRequired: true
});

extend("requiredBIN", {
  validate(value, args) {
    let validation = true;
    if (args.visible === "true") {
      if (value.includes(",")) {
        let nums = value.split(",");
        for (let index = 0; index < nums.length; index++) {
          const element = nums[index];
          if (element.length != 6 || !/^-?[\d.]+(?:e-?\d+)?$/.test(element)) {
            validation = false;
            break;
          }
        }
      } else {
        if (value.length == 6 && /^-?[\d.]+(?:e-?\d+)?$/.test(value)) {
          validation = true;
        } else {
          validation = false;
        }
      }
      return {
        required: true,
        valid: validation
      };
      return i18n.t("PROMOS.VALIDATION_MSGS.BIN");
    } else {
      validation = true;
      return {
        required: false,
        valid: validation
      };
    }
    return i18n.t("PROMOS.VALIDATION_MSGS.BIN");
  },
  params: ["visible"],
  message: i18n.t("PROMOS.VALIDATION_MSGS.BIN"),
  computesRequired: true
});

extend("requiredDate", {
  validate(value) {
    return {
      required: true,
      valid: value != ""
    };
    return i18n.t("PROMOS.VALIDATION_MSGS.REQ");
  },
  computesRequired: true
});

extend("maxDiscountAmt", {
  validate(value, args) {
    if (parseFloat(value) > parseFloat(args.max)) {
      const len = value.length
      return len > 0 && /^\d*\.?\d*$/.test(value) && parseInt(value) != 0
    }
    return i18n.t("PROMOS.VALIDATION_MSGS.MIN_PURCHASE_AMT");
  },
  params: ["max"],
  message: i18n.t("PROMOS.VALIDATION_MSGS.MIN_PURCHASE_AMT")
});

extend("requiredName", {
  validate(value) {
    let len = (value === undefined) ? 0 : value.length;
    return {
      required: true,
      valid: len != 0,
    };
  },
  computesRequired: true,
});

extend("requiredURL", {
  validate(value) {
    return {
      required: true,
      valid: /^(https):\/\/[^ "]+$/.test(value),
    };
  },
  computesRequired: true,
  message: i18n.t("WEBHOOKS.VALIDATION_MSGS.URL"),
});

extend("requiredEvent", {
  validate(value) {
    return {
      required: true,
      valid: value != "",
    };
  },
  computesRequired: true,
});