import { httpClient } from './httpClient.js';

const
    LISTWEBHOOKS = 'dashboard/webhook',
    CREATEWEBHOOK = 'dashboard/webhook',
    GETWEBHOOK = 'dashboard/webhook/',
    UPDATEWEBHOOK = 'dashboard/webhook/',
    DELETEWEBHOOK = 'dashboard/webhook/';


const listWebhook = (listParams) => httpClient.dashboardService.get(LISTWEBHOOKS, { "params": listParams });
const createWebhook = (webhook) => httpClient.dashboardService.post(CREATEWEBHOOK, webhook);
const getWebhook = (id) => httpClient.dashboardService.get(GETWEBHOOK + id);
const updateWebhook = (id, webhook) => httpClient.dashboardService.patch(UPDATEWEBHOOK + id, webhook);
const deleteWebhook = (id) => httpClient.dashboardService.delete(DELETEWEBHOOK + id);

export {
    listWebhook,
    createWebhook,
    getWebhook,
    updateWebhook,
    deleteWebhook
};
