<template>
  <CRow>
    <CCol md="10" sm="10">
      <CCard>
        <CCardHeader class="pt-4">
          <h5>{{ $t("WEBHOOKS.CREATE_HEADER") }}</h5>
        </CCardHeader>
        <CCardBody>
          <ValidationObserver ref="validationObserver">
            <CForm>
              <CRow>
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t("WEBHOOKS.LABELS.NAME") }}
                </CCol>
                <ValidationProvider
                  name="Webhook Name"
                  ref="Webhook Name"
                  rules="requiredName"
                  v-slot="{ errors }"
                  class="form-group col-6"
                >
                  <b-form-input
                    class="col-12 mb-0"
                    id="webhooklabel"
                    name="webhooklabel"
                    v-model="webhook.name"
                    type="text"
                  ></b-form-input>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider></CRow
              >
              <CRow>
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t("WEBHOOKS.LABELS.URL") }}
                </CCol>
                <ValidationProvider
                  name="Webhook Url"
                  ref="Webhook Url"
                  rules="requiredURL"
                  v-slot="{ errors }"
                  class="form-group col-6"
                >
                  <b-form-input
                    class="col-12 mb-0"
                    type="text"
                    v-model="webhook.url"
                    placeholder="https://merchantwebhook.com"
                    horizontal
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </CRow>

              <CRow>
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t("WEBHOOKS.LABELS.EVENT") }}
                </CCol>
                <ValidationProvider
                  name="Webhook Event"
                  ref="Webhook Event"
                  rules="requiredEvent"
                  v-slot="{ errors }"
                  class="col-sm-9"
                >
                  <b-form-select
                    v-model="webhook.event"
                    :options="events"
                    class="col-12"
                  ></b-form-select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </CRow>
            </CForm>
          </ValidationObserver>
        </CCardBody>
        <CCardFooter>
          <CRow class="float-left">
            <CCol sm class="text-center">
              <CButton @click.prevent="validatePromo" color="durianprimary">
                <CIcon name="cil-check-circle" />&nbsp;
                {{ $t("COMMON.CREATE") }}
              </CButton>
              <CButton @click="goWebhookTab">{{ $t("COMMON.BACK_BUTTON") }}</CButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import "./../../../validations/validationRules.js";
import WebhookBuilder from "./../../../models/builder/WebhookBuilder.js";
import { createWebhook } from "@/api/webhooks.api.js";

export default {
  name: "NewWebhook",  
  data() {
    return {
      webhook: {},
      events: [],
      selectedEvent: "",
      webhookTab: "webhooks",
    };
  },
  methods: {
    async validatePromo() {
      await this.$refs.validationObserver.validate().then((result) => {
        if (result) {
          this.addWebhook();
        }
      });
    },
    goWebhookTab(){
      this.$router.push("/settings?activeTab="+this.webhookTab);
    },
    addWebhook() {
      createWebhook(this.webhook)
        .then((response) => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.showSuccessToaster(this.$t("WEBHOOKS.TOASTS.ADDED"));
            this.$router.push("/settings?activeTab="+this.webhookTab);
          }
        })
        .catch(this.handleAPIError);
    },
    getEvents() {
      this.events = this.getWebhookEvents();
    },
  },
  mounted() {
    this.webhook = new WebhookBuilder().build();
    this.getEvents();
  },
};
</script>
<style>
</style>
