import Webhook from '../Webhook'
export default class WebhookBuilder {
    constructor(name = "", url = "", event = "") {
        this.name = name;
        this.url = url;
        this.event = event;
    }

    setPrimary(primary = true) {
        this.is_primary = primary;
        return this;
    }

    build() {
        return new Webhook(this);
    }
}